import React from 'react'

const asyncComponent = (
  importComponent: () => Promise<{
    default: React.ComponentType<any>
  }>
): React.ComponentType<any> => {
  const Component = React.lazy(importComponent)
  return React.memo(props => (
    <React.Suspense>
      <Component {...props} />
    </React.Suspense>
  ))
}

export default asyncComponent
export function AsyncComponent({
  render
}: {
  render: () => Promise<{
    default: React.ComponentType<any>
  }>
}) {
  const Cmp = asyncComponent(render)
  return <Cmp />
}
