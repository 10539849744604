import { NotificationManager } from '@sw/utils/NotificationManager/NotificationManager'
import { config } from '@sw/ui-context/ConfigContext'
// import LogRocket from 'logrocket'
import i18n from '@sw/ui-translations'
import { generateTraceparent } from '@sw/utils/uuidv4'
import { Message } from '@sw/utils/NotificationManager/types'
import ky, { Options, ResponsePromise, KyResponse, HTTPError } from 'ky'
import { setCallApi, setIsCancel } from '@sw/utils/api'
//#region utils
const toString = Object.prototype.toString

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
function isArray(val: any) {
  return Array.isArray(val)
}

/**
 * Determine if a value is undefined
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if the value is undefined, otherwise false
 */
function isUndefined(val: any) {
  return typeof val === 'undefined'
}

/**
 * Determine if a value is a Buffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Buffer, otherwise false
 */
function isBuffer(val: any) {
  return (
    val !== null &&
    !isUndefined(val) &&
    val.constructor !== null &&
    !isUndefined(val.constructor) &&
    typeof val.constructor.isBuffer === 'function' &&
    val.constructor.isBuffer(val)
  )
}

/**
 * Determine if a value is an ArrayBuffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an ArrayBuffer, otherwise false
 */
function isArrayBuffer(val: any) {
  return toString.call(val) === '[object ArrayBuffer]'
}

/**
 * Determine if a value is a FormData
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an FormData, otherwise false
 */
function isFormData(val: any) {
  return toString.call(val) === '[object FormData]'
}

/**
 * Determine if a value is a view on an ArrayBuffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a view on an ArrayBuffer, otherwise false
 */
function isArrayBufferView(val: any) {
  let result
  if (typeof ArrayBuffer !== 'undefined' && ArrayBuffer.isView) {
    result = ArrayBuffer.isView(val)
  } else {
    result = val && val.buffer && isArrayBuffer(val.buffer)
  }
  return result
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
function isObject(val: any) {
  return val !== null && typeof val === 'object'
}

/**
 * Determine if a value is a Function
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Function, otherwise false
 */
function isFunction(val: any) {
  return toString.call(val) === '[object Function]'
}

/**
 * Determine if a value is a Stream
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Stream, otherwise false
 */
function isStream(val: any) {
  return isObject(val) && isFunction(val.pipe)
}

/**
 * Determine if a value is a File
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a File, otherwise false
 */
function isFile(val: any) {
  return toString.call(val) === '[object File]'
}

/**
 * Determine if a value is a Blob
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Blob, otherwise false
 */
function isBlob(val: any) {
  return toString.call(val) === '[object Blob]'
}

//#endregion

export function isCancel(error: any): boolean {
  return error?.request?.signal?.aborted || error?.name === 'AbortError'
}
window.addEventListener('unhandledrejection', event => {
  //@ts-ignore
  if (isCancel(event.error || event.reason)) {
    event.preventDefault()
  }
})

let _access_token: string
export function setToken(access_token: string) {
  _access_token = access_token
}

async function parseError(error: HTTPError): Promise<Message> {
  const headers: any = {}
  error?.request.headers.forEach((value, key) => {
    headers[key] = value
  })
  const message: Message = {
    url: error?.request?.url,
    headers: headers,
    method: error?.request?.method,
    status: error?.response?.status,
    statusText: error?.response?.statusText,
    stack: error?.stack,
    //@ts-ignore
    data: error?.response?.data, // response from server
    requestBody: await error?.request.clone().text(), // data from request (POST, PUT) to server
    isHttpError: true
  }
  return message
}

let _baseUrl = ''
export function setBaseUrl(baseUrl: string) {
  _baseUrl = baseUrl
}
type KyInstance = typeof ky
const _instance: KyInstance = ky.create({
  // prefixUrl: baseUrl,
  // headers: { Accept: 'application/json' },
  timeout: false,
  hooks: {
    beforeRequest: [
      request => {
        request.headers.set('Authorization', `Bearer ${_access_token}`)
        request.headers.set('Accept-Language', i18n.language)
        request.headers.set('traceparent', generateTraceparent())
        //@ts-ignore
        const vsHeader = window['x-sw-c-access']
        if (vsHeader) {
          request.headers.set('x-sw-c-access', vsHeader)
        }
      }
    ],
    afterResponse: [
      async (_request, _options, response) => {
        const contentType = response.headers.get('content-type')?.toLowerCase()
        const newResponse = response.clone()
        const newResponseBody = response.clone()
        if (contentType) {
          if (contentType.includes('json')) {
            // @ts-ignore
            newResponse.data = await newResponseBody.json()
          } else {
            // @ts-ignore
            newResponse.data = await newResponseBody.text()
          }
        }
        /**
         * Пришлось это сделать т.к. для следующего вызова хука
         * вызывается метод clode и наша data теряется
         *
         * Следующий хук находится в kyTraker
         * */
        const originClone = newResponse.clone
        const clone = function () {
          const tmp = originClone.call(newResponse)
          // @ts-ignore
          tmp.data = newResponse.data
          return tmp
        }
        newResponse.clone = clone
        return newResponse
      }
    ],
    beforeError: [
      async (error: HTTPError) => {
        if (!isCancel(error) && !error.request.signal.aborted) {
          if (!(error.options as any)?.suppressShowError) {
            const message = await parseError(error)
            NotificationManager.error(message)
          }
        }
        return error
      }
    ]
  }
})

export default _instance
export interface ResponseExt<T = any> extends KyResponse {
  data: T
}
export interface AxiosPromiseCancelable<T = any> extends ResponsePromise {
  abort(): void
  then: Promise<ResponseExt<T>>['then']
}

export type Method = Uppercase<
  Exclude<keyof KyInstance, 'stop' | 'extend' | 'create'>
>

export const callApi = <T = any>(
  url: string,
  method: Method = 'GET',
  body?: any | null,
  header?: Record<string, string>,
  suppressShowError: boolean = false
): AxiosPromiseCancelable<T> => {
  // const curZone = Zone.current
  const controller = new AbortController()
  const request: Options = {
    method: method,
    signal: controller.signal
  }
  if (body) {
    if (
      isFormData(body) ||
      isArrayBuffer(body) ||
      isBuffer(body) ||
      isStream(body) ||
      isFile(body) ||
      isBlob(body)
    ) {
      request.body = body
    } else if (isArrayBufferView(body)) {
      request.body = body.buffer
    } else {
      request.json = body
    }
  }
  if (header) {
    request.headers = header
  }
  // if (acc) {
  //   request.headers = { ...request.headers, filter: acc }
  // }
  ;(request as any).suppressShowError = suppressShowError

  if (url.startsWith('/')) {
    request.prefixUrl = ''
    url = new URL(document.URL).origin + url
  }
  //NOTE: если в URL передан полный адрес, убираем из префикса baseUrl
  if (url.startsWith('http')) {
    request.prefixUrl = ''
  } else {
    request.prefixUrl = _baseUrl
  }

  const methodName: Lowercase<Method> =
    method.toLowerCase() as Lowercase<Method>
  const req: AxiosPromiseCancelable<T> = _instance[methodName](
    url,
    request
  ) as AxiosPromiseCancelable<T> //ResponsePromise
  // const req = _instance[methodName]<T>(request) as AxiosPromiseCancelable<T>
  req.abort = () => controller.abort()
  return req
}

setCallApi(callApi)
setIsCancel(isCancel)
