import React from 'react'

export interface GlobalConfig {
  idp: {
    authority: string
  }
  /**
   * Адрес gateway
   */
  apiUrl?: string
  /**
   * Показать пункт меню по смене пароля у пользователя
   */
  apiChangePassword?: string
  /**
   * Url для для записи веб сессий пользователя
   */
  openReplayUrl?: string
  /**
   * Зашифрованный ключ к openReplay
   */
  openReplayKey?: string
  isInline?: boolean
  /**
   * Показать панель счетов
   */
  enableAccountPanel?: boolean
  /**
   * Показывать операции в виджете список сделок
   */
  enableDealsOperation?: boolean
  /**
   * Настройка нотификации, отдает в clipboard
   */
  fullErrorReport?: boolean
  /**
   * Отключить FxNDF
   */
  disableFxNDF?: boolean
  qouteTimerMs?: number
  /**
   * Разрешить/запретить спот. При значение false спот запрещен
   */
  isAllowedSpot?: boolean
  /**
   *  Минимальный период terminationDate в fixed/fixed
   */
  minTermPeriodXCCY?: string
  /**
   * Максимальный период terminationDate в fixed/fixed. Пример настройки: "10Y"
   */
  maxTermPeriodXCCY?: string
  /**
   * Тема по умолчанию для компонента Reporter
   */
  reportTheme?: string
  /**
   * Адрес файла\*.[json] - При первой загрузке приложения, когда localStorage пустой,
   * рабочие столы будут загружены из файла, который указан,
   * если произошла ошибка при загрузке файла
   * (неверный формат/файл пустой/файл отсутствует),
   * то будут выставлены 2 пустых дефолтных рабочих стола.
   * Структура файла с рабочими столами
   */
  defaultDashboard?: string
  /**
   * Список названий инструментов которые нужно показывать по умолчанию
   * в панели просмотра быстрых курсов
   * @example ["USDRUB"]
   */
  marqueeCcyPairs?: string[]
  /**
   * Адрес websocket. Если не указан, то не подключаемся
   */
  ws?: string
  /**
   * Локальный репортер для работы в локальной сети банка
   */
  localReport?: string
  /**
   * Прием нотификаций из back для обновления отчётов и нотификации пользователя
   */
  rnMessageNotifications?: boolean
  /**
   * адрес картинки \*.[jpg/png/gif/svg] (размер картинки `150px х 36px`)
   * для логотипа, e.g. `/logo.png` укажет на logo.png в папке
   */
  appLogo?: string
  /**
   * Ссылка на сервис позволяющий получить контент страницы врешнего виджета.
   * Сейчас это apps/proxyserver с таким url: http://localhost:3001/?url=
   */
  externalWidgetResolver?: string
  /**
   * Список url где хранятся репозитории с инфомации о доступных внешних виджетов для fdc3
   */
  externalAppDirectories?: string[]
  /**
   * Генерировать testid для UI
   */
  generateTestId?: boolean
  /**
   * Пауза после успешной операции в форме trade. Для кабинета биржи
   */
  tradeExecuteTimeout?: number
  /**
   * Записывать в openReplay сообщения из websocket
   */
  traceWs?: boolean
  /**
   * Дефолтные значения пользовательских настроек интерфейса
   */
  interfaceSettings?: {
    preferSystemCS?: boolean
    colorScheme?: string
    fontSize?: number
  }
  /**
   * Показать смену языка в настройках сайта
   */
  showLanguageSettings?: boolean
  /**
   * Включить доступные отчёты в список виджетов
   */
  enableReports?: boolean
  /**
   * Список виджетов Навигатор, которые можно отключить
   */
  disableWidgets?: Record<string, true>
}
export let config: GlobalConfig = {
  idp: {
    authority: ''
  }
}

export function setConfig(newValue: GlobalConfig) {
  config = newValue
}

export const ConfigContext = React.createContext<GlobalConfig>(config)

export default ConfigContext
