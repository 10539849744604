import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import ConfigContext, {
  GlobalConfig,
  setConfig
} from '@sw/ui-context/ConfigContext'

import { callApi } from '@sw/utils/api'
import { setBaseUrl } from '@sw/starter/axiosGlobal'
import { AsyncComponent } from '@sw/ui-core/asyncComponent'
import { setModeChat } from '@sw/utils/config'

const getLocales = async () => ({
  curves: (await import('@sw/m-curves/locales/index')).default,
  bulletinBoard: (await import('@sw/m-bulletin-board/locales/index')).default,
  chat: (await import('@sw/m-chat/locales/index')).default,
  news: (await import('@sw/m-news/locales/index')).default,
  auction: (await import('@sw/m-auction/locales/index')).default,
  calculator: (await import('@sw/m-calculator/locales/index')).default,
  subsoil: (await import('@sw/m-subsoil/locales/index')).default,
  liquityProvider: (await import('@sw/m-liquidity-provider/locales/index'))
    .default
})

const buildNum = __BUILD_TIMESTAMP__ //You will get right val in this.
const AppAsync = () => <AsyncComponent render={() => import('./App')} />

// https://stackoverflow.com/a/64696177/15306874
window.addEventListener('error', e => {
  // prompt user to confirm refresh
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload()
  }
})
const rootEl = document.getElementById('app-site')
const root = createRoot(rootEl!)

const render = () => {
  callApi('/config.json' + (buildNum ? '?v=' + buildNum : '')).then(
    async response => {
      const config = (await response.json()) as GlobalConfig
      setModeChat(true)
      setConfig(config)
      setBaseUrl(config.apiUrl ?? '')
      const { setDefaultLanguage } = await import('@sw/ui-translations')
      setDefaultLanguage('en-US', buildNum, await getLocales())
      const MainApp = (await import('@sw/starter/Portal')).default
      root.render(
        <StrictMode>
          <ConfigContext.Provider value={config}>
            <MainApp App={AppAsync} />
          </ConfigContext.Provider>
        </StrictMode>
      )
    }
  )
  // }
}

render()
